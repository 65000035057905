import type { Cart } from '~/packages/commerce/types/cart';

const cart = ref<Cart | null>(null);
const isCartOpen = ref<Boolean>(false);
const newItem = ref<Boolean>(false);
const disableDeleteButton = ref<Boolean>(false);
export const useCart = () => {
    const fetchCart = async () => {
        const response = await $fetch(
            `${useRuntimeConfig().public.businessPortal.url}/int/session/cart`,
            {
                credentials: 'include',
                server: false,
            },
        );
        cart.value = await $fetch(
            `/api/commerce/cart/${response?.data?.cartId}`,
            {
                server: false,
            },
        );
    };

    fetchCart();

    const openCart = () => {
        isCartOpen.value = true;
    };
    const toggleCart = () => {
        isCartOpen.value = !isCartOpen.value;
        newItem.value = false;
    };
    const closeCart = () => {
        isCartOpen.value = false;
        newItem.value = false;
    };

    const addLineItemToCart = async (
        productVariant: string,
        quantity: number,
        addons: string[] = [],
    ) => {
        if (!cart.value) {
            await fetchCart();
        }

        cart.value = await $fetch(
            `/api/commerce/cart/${cart.value.id}/lineItems/${productVariant}`,
            {
                body: {
                    data: {
                        addons,
                        id: productVariant,
                        quantity: quantity,
                    },
                },
                method: 'put',
            },
        );
        newItem.value = true;
        openCart();
    };

    const deleteLineItem = async (productVariant: string) => {
        disableDeleteButton.value = true;
        cart.value = await $fetch(
            `/api/commerce/cart/${cart.value.id}/lineItems/${productVariant}`,
            {
                method: 'delete',
            },
        );
        newItem.value = false;
        disableDeleteButton.value = false;
    };

    return {
        addLineItemToCart,
        cart,
        closeCart,
        deleteLineItem,
        disableDeleteButton,
        fetchCart,
        isCartOpen,
        newItem,
        openCart,
        toggleCart,
    };
};
